@import url('https://fonts.googleapis.com/css?family=Roboto,Inconsolata');

html {
  font-size: 14px;

  @media (min-width: 600px) {
    font-size: 24px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

address {
  font-family: 'Inconsolata', monospace;
  font-style: normal;
}
